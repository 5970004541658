<template>
  <div>
    <hr
      class="hr-text"
      data-content="Kho trung gian"
      style="font-weight: 600"
    />
    <b-row v-if="editable">
      <b-col>
        <b-button size="sm" class="mb-2" variant="warning" @click="addRow">
          <i class="fas fa-plus" style="font-size: 1rem"></i>
          Thêm mới
        </b-button>
      </b-col>
    </b-row>
    <b-table
      bordered
      hover
      :fields="fields"
      :items="items"
      v-if="renderComponent"
    >
      <template v-slot:cell(storeName)="row">
        <vue-autosuggest
          v-if="editable"
          v-model="row.item.storeName"
          :suggestions="filteredOptionStores"
          @selected="onSelectedStore($event, row.item)"
          :limit="10"
          @input="onInputChangeStore"
          :input-props="{
            class: 'autosuggest__input',
            placeholder: 'Chọn kho',
            style: 'border-radius:0px!important',
          }"
        >
          <template slot-scope="{ suggestion }">
            <div>{{ suggestion.item.name }}</div>
          </template>
        </vue-autosuggest>
        <span v-else>{{ row.item.storeName }}</span>
      </template>
      <template v-slot:cell(inChargeEmployeeName)="row">
        <vue-autosuggest
          v-if="editable"
          v-model="row.item.inChargeEmployeeName"
          :suggestions="filteredOptionsEmployee"
          @selected="onSelectedEmployee($event, row.item)"
          :limit="10"
          @input="onInputChangeEmployee"
          :input-props="{
            class: 'autosuggest__input',
            placeholder: 'Chọn NV phụ trách',
            style: 'border-radius:0px!important',
          }"
        >
          <template slot-scope="{ suggestion }">
            <div>{{ suggestion.item.name }}</div>
          </template>
        </vue-autosuggest>
        <span v-else>{{ row.item.inChargeEmployeeName }}</span>
      </template>
      <template v-slot:cell(actions)="row">
        <v-icon
          v-if="editable"
          small
          class="text-danger text-center"
          @click="deleteItemInArray(row.item.count)"
          v-b-tooltip
          title="Xóa"
          >mdi-delete</v-icon
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { makeToastFaile, xoa_dau } from '../../../utils/common';
import ApiService from '@/core/services/api.service';
export default {
  props: ['items', 'editable'],
  data() {
    return {
      renderComponent: true,
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'storeName',
          label: 'Kho',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '40%',
          },
        },
        {
          key: 'inChargeEmployeeName',
          label: 'Nhân viên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '40%',
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
      ],
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      filteredOptionStores: [],
      optionStores: [
        {
          data: [],
        },
      ],
    };
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    addRow() {
      if (this.items.length >= 6) {
        return makeToastFaile('Chỉ cho phép tối đa 6 kho trung gian')
      }

      this.items.push({
        count: 0,
        storeId: null,
        storeName: '',
        inChargeEmployeeId: null,
        inChargeEmployeeName: '',
      });

      this.items.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onInputChangeEmployee(text) {
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedEmployee(option, row) {
      row.inChargeEmployeeId = option.item.id;
      row.inChargeEmployeeName = option.item.name;
    },
    onInputChangeStore(text) {
      const filteredData = this.optionStores[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionStores = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedStore(option, row) {
      row.storeId = option.item.id;
      row.storeName = option.item.name;
    },
    fetchStore: function () {
      this.optionStores[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        this.optionStores[0].data = response.data.data;
        this.filteredOptionStores = [{ data: this.optionStores[0].data }];
      });
    },
    fetchEmployee: function () {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.optionsEmployee[0].data = employees.map((element) => {
          return {
            id: element.id,
            name: element.fullName,
            code: element.code,
          };
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].count === id) {
          this.items.splice(i, 1);
        }
      }

      this.items.forEach((element, index) => {
        element.count = index + 1;
      });
    },
  },
  created() {
    this.fetchStore();
    this.fetchEmployee();
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
